import { AnimatePresence, motion } from "framer-motion"
import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import Container from "../Container/Container"
import './SmallHeroNew.css'

const SmallHeroNew = (props) => {

    const image = props.image
    const title = props.title
    const desc = props.desc
    const overlay = props.overlay
    const overlayImage = props.overlayImage
    const breadcrumbs = props.breadcrumbs
    const subtitleData = props.subtitle
    const subtitle = subtitleData !== undefined ? subtitleData.map((e) => (
        <div>
            {e}
        </div>
    )) : null;

    const [width, setWidth] = useState()

    useEffect(() => {
        setWidth(window.innerWidth)
      }, []);


    return(
            <div key={title} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ ease: "easeIn" , duration: .35 }} className="relative bg-blue bg-center bg-no-repeat bg-cover overflow-hidden hero" style={width > 1024 ? {backgroundImage: `url(${image})`, backgroundSize: '50%', backgroundPosition: 'right top'} : {backgroundImage: `url(${image})`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
                <div className={` flex flex-col justify-end pb-8 lg:pb-0 lg:justify-center h-full w-full max-w-none text-light xl:px-0 px-8 lg:pt-0 bg-blue-light lg:bg-transparent`}>
                    <Container>
                        <motion.h1 className="w-full lg:w-1/2" initial={{ opacity: 0 }} animate={{ opacity: 1 }}  exit={{ opacity: 0 }} transition={{ duration: .3, delay: .15 }}>{title}</motion.h1>
                        <div className="flex flex-row justify-between lg:w-1/3">{subtitle}</div>
                        <motion.p initial={{ opacity: 0 }} animate={{ opacity: 1 }}  exit={{ opacity: 0 }} transition={{ duration: .3, delay: .30 }} className='lg:w-1/2 pr-12'>{desc}</motion.p>
                        { breadcrumbs !== undefined ?
                            <span>Je bent nu hier: <Link to="/" className="text-light">Home </Link>{breadcrumbs} </span>
                        : null}
                    </Container>
                    
                    { overlay ? 
                        <motion.img initial={{ translateY: 200, rotate: -45, translateX: -50 }} animate={{ translateY: 0, rotate: 0, translateX: 0 }}  exit={{ translateY: 200, rotate: -45, translateX: -50 }} transition={{ duration: .5, delay: 0 }} src={overlayImage} alt="G-logo" className="absolute -bottom-12 m-0 left-0 h-2/5" />
                    : null }

                </div>
            </div>
    )
}

export default SmallHeroNew