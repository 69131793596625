import * as React from "react"
import { graphql } from "gatsby"
import Container from "../components/Container/Container"
import SmallHero from "../components/SmallHero/SmallHero"
import SmallHeroNew from "../components/SmallHeroNew/SmallHeroNew"
import G from "../images/groenhout_g.png"
import Seo from "../components/Seo/Seo"

const Post = ({data}) => {
  const content = data.wpPost.content
  const image  = data.wpPost.featuredImage !== null ? data.wpPost.featuredImage.node.mediaItemUrl : ``
  if(!data) return null
  
  return (
    <>
      <Seo title={data.wpPost.seo.title} description={data.wpPost.seo.metaDesc}></Seo>
      <SmallHeroNew title={data.wpPost.title} overlay={true} overlayImage={G} image={image !== undefined ? image : ''}></SmallHeroNew>
      <Container styles={`px-5 xl:w-7/12 lg:8/12 md:w-10/12 my-16 post`}>
        <h1 className="text-blue">{data.wpPost.title}</h1>
        <div dangerouslySetInnerHTML={{__html: content}}></div>
      </Container>
    </>

  )
}

export default Post;

export const postQuery = graphql`
  query($id: Int!){
    wpPost(databaseId: {eq: $id}){
      title
      content
      featuredImage {
        node {
          mediaItemUrl
        }
      }
      seo{
        metaDesc
        title
      }
    }
  }
  `