import { AnimatePresence, motion } from "framer-motion"
import { Link } from "gatsby"
import React from "react"
import Container from "../Container/Container"
import "./SmallHero.css"

const SmallHero = (props) => {

    const image = props.image
    const title = props.title
    const desc = props.desc
    const overlay = props.overlay
    const overlayImage = props.overlayImage
    const breadcrumbs = props.breadcrumbs
    const subtitleData = props.subtitle
    const subtitle = subtitleData !== undefined ? subtitleData.map((e) => (
        <div>
            {e}
        </div>
    )) : null;
    
    // console.log(props.animKey)

    return(
            <div key={title} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ ease: "easeIn" , duration: .35 }} className="relative bg-gray bg-center bg-no-repeat bg-cover overflow-hidden small_hero" style={{backgroundImage: `url(${image})`}}>
                <div className={` flex flex-col justify-end pb-4 md:pb-24 h-full w-full max-w-none text-light xl:px-0 px-8 lg:pt-0 pt-48 ${overlay ? 'bg-blue-medium' : 'bg-transparent'}`}>
                    <Container>
                        <motion.h1 initial={{ opacity: 0 }} animate={{ opacity: 1 }}  exit={{ opacity: 0 }} transition={{ duration: .3, delay: .15 }}>{title}</motion.h1>
                        <div className="flex flex-row justify-between lg:w-1/3 md:w-8/12">{subtitle}</div>
                        <motion.p initial={{ opacity: 0 }} animate={{ opacity: 1 }}  exit={{ opacity: 0 }} transition={{ duration: .3, delay: .30 }}>{desc}</motion.p>
                        { breadcrumbs !== undefined ?
                            <span>Je bent nu hier: <Link to="/" className="text-light">Home </Link>{breadcrumbs} </span>
                        : null}
                    </Container>
                    
                    { overlay ? 
                        <motion.img initial={{ translateY: 200, rotate: -45, translateX: -50 }} animate={{ translateY: 0, rotate: 0, translateX: 0 }}  exit={{ translateY: 200, rotate: -45, translateX: -50 }} transition={{ duration: .5, delay: 0 }} src={overlayImage} alt="G-logo" className="absolute -bottom-16 m-0 -right-16 lg:h-3/5 h-2/5" />
                    : null }

                </div>
            </div>
    )
}

export default SmallHero